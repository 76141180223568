import React from 'react';

const AccessTrain = () => {
  return(
    <div className="access-detail">
      <div className="details">
        <span className="node">東京駅</span>
        <span className="link">北陸新幹線約70分</span>
        <span className="node">軽井沢駅</span>
        <span className="link">送迎バス約15分</span>
        <span className="node">レセプションハウス</span>
      </div>
      <div className="caption">
        <p>挙式前の送迎バスの運行予定時刻は<br/><strong>12:00 / 12:30 / 13:00</strong><br/>となっております<br/>最新の送迎バスの運行予定は<br/>ルグラン軽井沢ホテル＆リゾートの<br/>ホームページにてご確認ください</p>
      </div>
      <div className="tips">
        <p>11月3日の新幹線は 9月26日の事前予約で<br/>「えきねっとトクだ値」設定車両を予約すれば<br/>15%~35%OFFで指定席券が購入可能です</p>
        <p>「えきねっとトクだ値」席は事前予約終了時には<br/>ほとんど売り切れているので注意です</p>
      </div>
    </div>
  );
};

export default AccessTrain;