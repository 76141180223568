import { ReactNode } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

export const FadeAnimation = (props) => {
  const { children } = props;
  const { ref, inView } = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });

  return (
    <FadeElem inView={inView ? 1 : 0} ref={ref}>
      {children}
    </FadeElem>
  );
};

const FadeElem = styled.span`
  display: inline-block;
  transition: opacity 1s cubic-bezier(0.47, 0, 0.745, 0.715);
  opacity: ${(props) => props.inView};
`;