import React from 'react';
import { Link } from 'react-router-dom';
import LineOfficial from './LineOfficial';

const Complete = () => {
  return(
    <div className="complete">
      <p className="complete-message">出席情報の登録が完了しました</p>
      <LineOfficial />
      <Link to="/"><p>トップページへ戻る</p></Link>
    </div>
  );
};

export default Complete;