import '../styles/App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { mediaQuery, useMediaQuery } from '../hooks/useMediaQuery'
import Home from '../components/Home';
import Rsvp from '../components/Rsvp';
import Faq from '../components/Faq';
import Karuizawa from '../components/Karuizawa';
import NotFound from '../components/NotFound';
import HeaderImage from '../components/HeaderImage';
import Footer from '../components/Footer';
import MainVisual from '../components/MainVisual';
import Confirm from '../components/Comfirm';
import Complete from '../components/Complete';
import Access from '../components/Access';
import ScrollToTop from '../components/ScrollToTop';
import usePageTracking from '../hooks/usePageTracking';

function PageTracker() {
  usePageTracking();
  return null;
}

const App = () => {
  const isSp = useMediaQuery(mediaQuery.sp);
  return (
    <BrowserRouter>
      <PageTracker />
      <ScrollToTop />
      {/* <Navbar /> */}
      <div className={isSp ? '' : 'pc'}>
      <Switch>
        <Route exact path="/">
          <MainVisual />
          
            <Home />
          
        </Route>
        <Route path="/rsvp">
          <HeaderImage />
          
          <Rsvp />
          
        </Route>
        <Route path="/faq">
          <HeaderImage />
          
          <Faq />
          
        </Route>
        <Route path="/access">
          <HeaderImage />
          
          <Access />
          
        </Route>
        <Route path="/karuizawa">
          <HeaderImage />
          
          <Karuizawa />
          
        </Route>
        <Route path="/confirm">
          <HeaderImage />
          
          <Confirm />
          
        </Route>
        <Route path="/complete">
          <HeaderImage />
          
          <Complete />
          
        </Route>
        <Route path="*">
          <HeaderImage />
          
          <NotFound />
          
        </Route>
      </Switch>
      
      <Footer />
      </div>
      
    </BrowserRouter>
  );
}

export default App;