import React from 'react';
import firstViewImage from '../images/first_view.jpg';
import firstViewTitle from '../images/title.png';
import { mediaQuery, useMediaQuery } from '../hooks/useMediaQuery'

const MainVisual = ({isFullScreen}) => {
  const isSp = useMediaQuery(mediaQuery.sp);
  return(
    <div className="main-visual">
      <div className="scroll-infinity__list">
        <img className="first_view_image" src={firstViewImage} />
        <img className="first_view_image" src={firstViewImage} />
      </div>
      <div className="text-box">
        <img className={isSp ? "first_view_title fadein" : "first_view_title_pc fadein"} src={firstViewTitle} />
        <p className="name fadein-delay">Yuki & Haruka</p>
        <p className="date fadein-delay">2023.11.03</p>
      </div>
      <div className="scroll-down"><span>Scroll</span></div>
    </div>
  );
};

export default MainVisual;