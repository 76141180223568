import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GoogleMap from './GoogleMap';
import { FadeAnimation } from './FadeAnimation';

const Karuizawa = () => {
  return(
    <div className="karuizawa">
      <FadeAnimation>
        <h2 className="eng">Karuizawa</h2>
      </FadeAnimation>
      <FadeAnimation>
        <p>軽井沢のステキな施設やレストランをまとめました<br/>ぜひ軽井沢で楽しんでいってください</p>
      </FadeAnimation>
      <FadeAnimation>
        <Tabs>
            <TabList>
              <Tab>観光マップ</Tab>
              <Tab>グルメマップ</Tab>
            </TabList>
            <TabPanel>
              <GoogleMap id="1LlHUWgS1zuTnwZyhF2TbHgUWl5iqNhU" />
            </TabPanel>
            <TabPanel>
              <GoogleMap id="1xsH4YSQgOgL0Py-ALJ0aopb6g1Jmi5w" />
            </TabPanel>
          </Tabs>
      </FadeAnimation>
    </div>
  );
};

export default Karuizawa;