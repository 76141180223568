import React from 'react';

const GoogleMap = ({id}) => {
  return(
    <div className="google-maps">
      <iframe className="mymap" src={`https://www.google.com/maps/d/u/0/embed?mid=${id}&ehbc=2E312F&noprof=1`}></iframe>
    </div>
  );
};

export default GoogleMap;