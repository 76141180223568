import React from 'react';

const AccessCar = () => {
  return(
    <div className="access-detail">
      <div className="details">
        <span className="node">東京</span>
        <span className="link">関越道•上信越道約2時間</span>
        <span className="node">碓氷軽井沢I.C.</span>
        <span className="link">県道43号•92号約15分</span>
        <span className="node">レセプションハウス</span>
      </div>
      <div className="caption">
        <p>レセプションハウス横の駐車場にお停めください<br/>満車の場合は ホテル内の別の駐車場に<br/>係員がご案内いたします</p>
      </div>
      <div className="tips">
        <p>例年 11月上旬の日中の軽井沢では積雪はないため<br/>冬用タイヤは不要と思われます<br/>冷え込む際に降雪の可能性はあるため<br/>天気予報をもとにタイヤの履替えをご検討ください</p>
      </div>
    </div>
  );
};

export default AccessCar;