import React from 'react';
import { FadeAnimation } from './FadeAnimation';
import brideImage from '../images/bride_image.png';
import groomImage from '../images/groom_image.png';
import brideName from '../images/bride_name.png';
import groomName from '../images/groom_name.png';

const Message = () => {
  return(
    <div className='message'>
      <FadeAnimation>
        <h2 className="eng">Message</h2>
      </FadeAnimation>
      <FadeAnimation>
        <div className='main-message'>
          <p className="title">謹啓</p>
          <p>皆さまにおかれましては<br/>ますますご清祥のこととお慶び申し上げます</p>
          <p>このたびわたしたちは結婚式を<br/>挙げることとなりました</p>
          <p>つきましては 日ごろお世話になっている皆さまに<br/>感謝を込めて ささやかな小宴を催したく存じます</p>
          <p>ご多用中 誠に恐縮ではございますが<br/>ぜひご出席いただきたく 謹んでご案内申しあげます</p>
          <p className="title">謹白</p>
        </div>
      </FadeAnimation>
      <div className="bride">
        <FadeAnimation>
          <h3 className="eng">Bride</h3>
        </FadeAnimation>
        <FadeAnimation>
          <img className="name" src={brideName} alt="bride_name"/>
        </FadeAnimation>
        <FadeAnimation>
        <div className="flex flex-center">
            <img className="image" src={brideImage} alt="bride_image"/>
          <p className="introduction">1994年4月15日生まれ 千葉県育ち<br/>幼少期からピアノにふれて育った<br/>最近は 発表会に向けて<br/>プロコフィエフのピアノソナタを練習中！<br/>新郎と演奏会にいくのが最近の楽しみ</p>
        </div>
        </FadeAnimation>
        <FadeAnimation>
          <p className="comment">遠方の方も多いと思いますが<br/>大好きな軽井沢の町でみなさんに<br/>お会いできることを楽しみにしております！</p>
        </FadeAnimation>
      </div>
      <div className="groom">
        <FadeAnimation>
          <h3 className="eng">Groom</h3>
        </FadeAnimation>
        <FadeAnimation>
          <img className="name" src={groomName} alt="groom_name"/>
        </FadeAnimation>
        <FadeAnimation>
        <div className="flex flex-center">
          <p className="introduction"> 1995年5月27日生まれ  千葉県育ち<br/>ものづくりと勉強がだいすき<br/>最近は「確率分布バトル」という<br/>学習型のカードゲームを作ってみた<br/>「景表法ブラックジャック」も作成中</p>
          <img className="image" src={groomImage} alt="groom_image"/>
        </div>
        </FadeAnimation>
        <FadeAnimation>
          <p className="comment">結婚式へのコミットランキング新郎の部で<br/>堂々の1位タイを取れるんじゃないかってくらい<br/>準備してみなさまをお待ちしております！</p>
        </FadeAnimation>
      </div>
    </div>
  );
};

export default Message;