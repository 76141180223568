import React from 'react';
import MainVisual from './MainVisual';
import Message from './Message';
import Event from './Event';
import Access from './Access';
import Announce from './Announce';
import LineOfficial from './LineOfficial';
import Faq from './Faq';
import Karuizawa from './Karuizawa';
import {FadeAnimation} from './FadeAnimation';

const Home = () => {
  return(
    <div className="home">
      <Message />
      <FadeAnimation>
        <div className="bg-brown">
          <Event />
          <Access />
        </div>
      </FadeAnimation>
      <FadeAnimation>
        <Announce />
      </FadeAnimation>
      <FadeAnimation>
        <LineOfficial />
      </FadeAnimation>   
        <Faq />
        <Karuizawa />
    </div>
  );
};

export default Home;