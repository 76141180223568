import React from 'react';
import { Link } from 'react-router-dom';
import firstViewImage from '../images/first_view.jpg';
import firstViewTitle from '../images/title.png';
import { mediaQuery, useMediaQuery } from '../hooks/useMediaQuery'

const HeaderImage = ({isFullScreen}) => {
  const isSp = useMediaQuery(mediaQuery.sp);
  return(
    <div className="header-image">
      <Link to='/'>
        <div className="scroll-infinity__list">
          <img className={isSp ? "first_view_image" : "first_view_image_pc"} src={firstViewImage} />
          <img className={isSp ? "first_view_image" : "first_view_image_pc"} src={firstViewImage} />
        </div>
        <div className="text-box">
          <img className={isSp ? "first_view_title" : "first_view_title_pc"} src={firstViewTitle} />
        </div>
      </Link>
    </div>
  );
};

export default HeaderImage;