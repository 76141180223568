import React from 'react';
import AccessTrain from './AccessTrain';
import AccessCar from './AccessCar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FadeAnimation } from './FadeAnimation';
import mapImage from '../images/maps.png';

const Access = () => {
  return(
    <div className="access">
      <FadeAnimation>
        <h2 className="eng">Access</h2>
      </FadeAnimation>
      <FadeAnimation>
        <div className="bg-white card">
          <img className="map" src={mapImage} alt="map"/>
          <a className='map' target='_blank' href="https://goo.gl/maps/DS5AsJrrZvrPnL1Z9">Google Mapで確認する</a>
          <p>北陸新幹線 軽井沢駅南口 より自動車で約15分<br/>上信越道 碓氷軽井沢I.C. より自動車で約15分</p>
          <p>ルグラン軽井沢ホテル＆リゾート内<br/>レセプションハウスにてお受付となります</p>
          <p>レセプションハウス横の駐車場にお停めください</p>
          <div className="tips">
            <p>レセプションハウスには<br/>クローク ロッカー 更衣室 がございます<br/>式場にてお着替えできますので<br/>ぜひ楽なご格好でお越しください</p>
          </div>
          <Tabs>
            <TabList>
              <Tab>新幹線</Tab>
              <Tab>自動車</Tab>
            </TabList>

            <TabPanel>
              <AccessTrain />
            </TabPanel>
            <TabPanel>
              <AccessCar />
            </TabPanel>
          </Tabs>
          <div className="weather-info">
            <h3>11月の軽井沢の平均気温</h3>
            <div className="temperature">  
              <div className="highest">
                <p>最高気温</p>
                <span>約<strong>10</strong>℃</span>
              </div>
              <div className="lowest">
                <p>最低気温</p>
                <span>約<strong>2</strong>℃</span>
              </div>
            </div>
            <p>冬用の厚手のアウターがあるとよさそうです</p>
          </div>
        </div>
      </FadeAnimation>
    </div>
  );
};

export default Access;