import React from 'react';
import { Link } from 'react-router-dom';
import Privacy from './Privacy';

const Footer = () => {
  return(
    <div className="footer">
    <ul className="md-flex">
      <li>
        <Link to="/">トップ</Link>
      </li>
      <li>
        <Link to="/rsvp">出席情報の登録</Link>
      </li>
      <li>
        <Link to="/access">アクセス</Link>
      </li>
      <li>
        <Link to="/karuizawa">軽井沢の紹介</Link>
      </li>
      <li>
        <Link to="/faq">よくある質問</Link>
      </li>
      <li>
        <Privacy />
      </li>
    </ul>
    <p className="copyright">2023 Yamamoto Yuki & Takaha Haruka</p>
    </div>
  );
};

export default Footer;