import React from 'react';
import { RiQuestionAnswerFill } from "react-icons/ri";
import { FadeAnimation } from './FadeAnimation';

const Faq = () => {
  return(
    <div className="faq">
      <FadeAnimation>
        <h2 className="eng">FAQ</h2>
      </FadeAnimation>
      <FadeAnimation>
        <p>想定されるお問い合わせに関する回答を<br/>こちらにざっくりまとめてみました<br/>なにかお困りのことがございましたら<br/>お気軽に新郎新婦までお問い合わせください</p>
      </FadeAnimation>
      <FadeAnimation>

        <h3>会場について</h3>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
                会場はルグラン旧軽井沢ですか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
              いいえ<br/>
              会場はルグラン軽井沢ホテル＆リゾートになります<br/>ルグラン旧軽井沢とは異なりますのでご注意ください
            </p>
          </div>
        </details>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
              受付時間よりも早く会場に到着しそうです
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
              受付開始前よりレセプションハウスにてお待ちいただけます<br/>受付開始時刻は気にせずご来場ください
            </p>
          </div>
        </details>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
                現地で着替えられるような場所はありますか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
              レセプションハウスに更衣室 ロッカー クロークがございますので お気軽な格好でご来場いただき お着替えいただけます
            </p>
          </div>
        </details>
        <h3>交通手段について</h3>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
                シャトルバスの時刻表はありますか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
              受付時刻に間に合うシャトルバスは 軽井沢駅南口ロータリーを<br/>12時 / 12時30分 / 13時 に発車するものになります<br/>最新の運行情報はルグラン軽井沢ホテル＆リゾートのホームページをご確認ください
            </p>
            <p>
              <a href="https://www.legrand-karuizawaresort.jp/access/" target="_break">https://www.legrand-karuizawaresort.jp/access/</a>
            </p>
          </div>
        </details>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
                帰りの新幹線の予約のために披露宴の終了予定時刻が知りたいです
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
            披露宴の終了は17時30分ごろを予定しております<br/>会場から軽井沢駅までのご移動を考慮して18時30分以降の新幹線を手配いただけるとよさそうです
            </p>
          </div>
        </details>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
              駐車場はありますか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
            レセプションハウス横に駐車場がございます<br/>満車の場合もホテル係員が別の駐車場へご案内いたします
            </p>
          </div>
        </details>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
              冬用タイヤに履き替えたほうがよいですか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
            例年 11月上旬の日中の軽井沢では降雪はないです<br/>しかし 冷え込むと積雪の可能性もあるため 直前の天気予報を参考にご検討ください
            </p>
          </div>
        </details>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
              お車代はどれくらいになりますか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
              交通費として 一律で世帯あたり一万円をお渡しさせていただく予定です<br/>遠方までいらしていただくのに申し訳ございません
            </p>
          </div>
        </details>
        <h3>LINE公式アカウントについて</h3>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
              LINE公式アカウントを友達追加したほうがよいですか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
            挙式当日までのアナウンスを おもな目的としております<br/>また 挙式当日にもLINE公式アカウントを用いた企画をご用意する予定ですので差し支えなければ友達追加いただけますと幸いです
            </p>
          </div>
        </details>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
              挙式日に急用が入ってしまった場合 / 体調が悪く参列できない場合はどうすればよいか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
            新郎 新婦に直接ご連絡いただくか LINE公式アカウントへお気軽にご連絡ください
            </p>
          </div>
        </details>
        <details>
          <summary>
            <div className="question">
              <RiQuestionAnswerFill />
              <p>
              LINE公式アカウントへ送ったメッセージは他の参列者に見えますか
              </p>
            </div>
          </summary>
          <div className="answer">
            <p>
            LINE公式アカウントあてにいただきましたメッセージは 新郎 新婦のみが拝見いたします<br/>どうぞお気軽にメッセージにてご相談ください
            </p>
          </div>
        </details>
      </FadeAnimation>
    </div>
  );
};

export default Faq;