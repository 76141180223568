import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Privacy from './Privacy';
import { FadeAnimation } from './FadeAnimation';


const Rsvp = () => {
  
  const prefecture = [
    '東京都',
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県'
  ];

  const howtocome = [
    '新幹線',
    '自家用車',
    'レンタカー',
    '未定'
  ];
  const [ formData, setFormData ] = useState({
    attendance: '出席',
    name: '',
    furigana: '',
    address: '',
    email: '',
    tel: '',
    allergy: '',
    prefecture: '東京都',
    howtocome: '新幹線',
    content: '',
  });

  const onChangeHandler = (e) => {
    e.persist();
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value})
  }

  const [attendance, setAttendance] = useState('出席');

  const onChangeRadioHandler = (e) => {
    const { name, value } = e.target
    setAttendance(value);
    setFormData({ ...formData, [name]: value})
  };

  const [count, setCount] = useState(0);
  const countUp = () => setCount(count + 1);
  const reduce = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  }; 

  const [ childFormData, setChildFormData ] = useState([
    {
      name: '',
      furigana: '',
      allergy: '',
      prefecture: '東京都',
    }
  ]); 

  useEffect(() => {
    setChildFormData(prevChildFormData => {
      const newChildFormData = [];
      for (let i = 0; i < count; i++) {
        if (prevChildFormData[i]) {
          newChildFormData.push(prevChildFormData[i]);
        } else {
          newChildFormData.push({
            name: '',
            furigana: '',
            allergy: '',
            prefecture: '東京都',
          });
        }
      }
      return newChildFormData;
    });
  }, [count]);

  const onChangeChildHandler = (e, index) => {
    const updatedChildFormData = [...childFormData];
    updatedChildFormData[index][e.target.name] = e.target.value;
    setChildFormData(updatedChildFormData);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.formData) {
      setFormData(location.state.formData);
    }
  }, [location]);

  useEffect(() => {
    if (location.state && location.state.childData) {
      setChildFormData(location.state.childData);
      setCount(location.state.childData.length);
    }
  }, [location]);

  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (formData.attendance === '出席') {
      if (formData.name && formData.furigana && formData.address && formData.email && formData.tel && formData.howtocome) {
        setIsCompleted(true);
      } else {
        setIsCompleted(false);
      }
    } else {
      if (formData.name && formData.furigana) {
        setIsCompleted(true);
      } else {
        setIsCompleted(false);
      }
    }
  }, [formData]);

  const [isChildCompleted, setIsChildCompleted] = useState(true);

  useEffect(() => {
    if (count > 0) {
      for (let i = 0; i < count; i++) {
        if (childFormData[i].name && childFormData[i].furigana) {
          setIsChildCompleted(true);
        } else {
          setIsChildCompleted(false);
          break;
        }
      }
    } else {
      setIsChildCompleted(true);
    }
  }, [childFormData]);


  return(
    <div className="rsvp">
      <FadeAnimation>
        <h2 className="eng">RSVP</h2>
        <p>以下のフォームより出席情報の<br/>ご登録をお願いいたします<br/>なお ご登録いただく個人情報は<br/>プライバシーポリシーに記載された<br/>利用目的の範囲内で利用いたします<br/>*は必須項目です</p>
      <div className="rsvp-form">
        <div className="radio">
          <input className="visually-hidden" name='attendance' type='radio' onChange={onChangeRadioHandler} value='出席' id="attendance" checked={attendance === '出席'} /><label htmlFor='attendance' className="attendance">出席</label>
          <input className="visually-hidden" name='attendance' type='radio' onChange={onChangeRadioHandler} value='欠席' id="absence" checked={attendance === '欠席'} /><label htmlFor='absence' className="attendance">欠席</label>
        </div>
        <label>お名前*</label>
          <input name='name' type='text' onChange={onChangeHandler} value={formData.name} />
        <label>ふりがな*</label>
        <input name='furigana' type='text' onChange={onChangeHandler} value={formData.furigana} />
        {attendance !== '欠席' && (
          <>
            <label>住所*</label>
            <input name='address' type='text' onChange={onChangeHandler} value={formData.address} />
            <label>メールアドレス*</label>
            <input name='email' type='email' onChange={onChangeHandler} value={formData.email} />
            <label>電話番号*</label>
            <input name='tel' type='tel' onChange={onChangeHandler} value={formData.tel} />
            <label>アレルギー</label>
            <p>お食事のアレルギーがございましたらご記載ください</p>
            <input name='allergy' type='text' onChange={onChangeHandler} value={formData.allegy} />
            <label>ご出身の都道府県</label>
            <p>差し支えのない範囲でご出身の都道府県を選択ください</p>
            <select name='prefecture' onChange={onChangeHandler} value={formData.prefecture}>
              {prefecture.map((prefecture) => (
                <option value={prefecture}>{prefecture}</option>
              ))}
            </select>
            <label>交通手段*</label>
            <p>ご予定されている式場への交通手段を選択ください<br/>なお 交通費として 一律で世帯あたり一万円をお渡しさせていただく予定です</p>
            <select name='howtocome' onChange={onChangeHandler} value={formData.howtocome}>
              {howtocome.map((howtocome) => (
                <option value={howtocome}>{howtocome}</option>
              ))}
            </select>
          </>
        )}
        <label>その他</label>
        <p>その他なにかございましたらご記入ください</p>
        <textarea name='content' type='text' onChange={onChangeHandler} value={formData.content} />
        
        {count > 0 && (
          childFormData.map((child, index) => (
            <div className="companion" key={index}>
              <label>{index+1}人目</label>
              <label>お連れさまのお名前*</label>
              <input name='name' type='text' onChange={(e) => onChangeChildHandler(e, index)} value={child.name} />
              <label>お連れさまのふりがな*</label>
              <input name='furigana' type='text' onChange={(e) => onChangeChildHandler(e, index)} value={child.furigana} />
              <label>お連れさまのアレルギー</label>
              <input name='allergy' type='text' onChange={(e) => onChangeChildHandler(e, index)} value={child.allergy} />
              <label>お連れさまのご出身の都道府県</label>
              <select name='prefecture' onChange={(e) => onChangeChildHandler(e, index)} value={child.prefecture}>
                {prefecture.map((prefecture) => (
                  <option value={prefecture}>{prefecture}</option>
                ))}
              </select>
            </div>
          ))
        )}
        {count > 0 && (
          <div className="add-form">
              <buttom onClick={reduce}>-お連れさまを減らす</buttom>
          </div>
        )}
        {attendance !== '欠席' && (
        <div className="add-form">
          <buttom onClick={countUp}>+お連れさまを追加する</buttom>
        </div>
        )}
      </div>
      <Privacy />
        {isCompleted && isChildCompleted ? (
          <div className="buttom-round">
            <Link to={{ pathname: '/confirm', state: { formData: formData, childData: childFormData}}}>
                内容を確認する
            </Link>
          </ div>
        ) : (
          <div className="buttom-round disable">
            <a>
              内容を確認する
            </a>
          </ div>
        )
        }
      </FadeAnimation>
    </div>
  );
};

export default Rsvp;