import React from 'react';
import useModal from '../hooks/useModal';

const Privacy = () => {
  const {Modal, openModal, closeModal} = useModal();

  return(
    <div className="privacy">
      <a onClick={openModal}>プライバシーポリシー</a>
      <Modal>
        <div className="privacy-modal">
          <h3>プライバシーポリシー</h3>
          <div className="privacy-modal-content">
            <p>
              山本 裕稀 および 山本(旧姓:高羽) 遥(以下「新郎 新婦」と記載)は 結婚式および披露宴に関し 以下のとおりプライバシーポリシーを定め 個人情報の保護を推進いたします
            </p>
            <h4>個人情報の管理</h4>
            <p>
              新郎 新婦は 個人情報(氏名 住所 生年月日 電話番号 メールアドレス Cookie IPアドレス情報等)の正確性および安全性確保のために セキュリティに万全の対策を講じます
            </p>
            <h4>個人情報の利用目的</h4>
            <p>
              新郎 新婦は 参列者のみなさまからお預かりした個人情報を以下の目的で利用いたします
            </p>
            <ul>
              <li>結婚式および披露宴に関するご案内</li>
              <li>結婚式および披露宴に関するお問い合わせへの対応</li>
              <li>結婚式および披露宴に関する企画の実施</li>
              <li>結婚式および披露宴に関するその他の業務</li>
            </ul>
            <h4>個人情報の第三者への開示・提供の禁止</h4>
            <p>
              新郎 新婦は 参列者のみなさまからお預かりした個人情報を適切に管理し 第三者に開示いたしません ただし 以下の場合はこの限りではありません
            </p>
            <ul>
              <li>参列者の同意が得られた場合</li>
              <li>法令や犯罪捜査などの法律手続の中で開示を要求された場合または公的機関等から正当な理由に基づき紹介を受けた場合</li>
              <li>人の生命・身体または財産の保護のために必要がある場合であって 本人の同意を得ることが困難である場合</li>
              <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって 本人の同意を得ることが困難である場合</li>
              <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して 協力する必要がある場合であって 本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</li>
            </ul>
            <h4>個人情報の安全対策</h4>
            <p>
              新郎 新婦は 個人情報の正確性および安全性確保のために セキュリティに万全の対策を講じます
            </p>
            <h4>個人情報の開示</h4>
            <p>
              新郎 新婦は ご本人から個人情報の照会を受けた場合 本人であることを確認の上で 個人情報の開示を行います
            </p>
            <h4>個人情報の訂正および削除</h4>
            <p>
              新郎 新婦は ご本人から個人情報の訂正または削除の依頼を受けた場合 本人であることを確認の上で 個人情報の訂正または削除を行います
            </p>
            <h4>個人情報の利用停止</h4>
            <p>
              新郎 新婦は ご本人から個人情報の利用停止の依頼を受けた場合 本人であることを確認の上で 個人情報の利用停止を行います
            </p>
            <h4>Cookieの利用</h4>
            <p>
              新郎 新婦は 招待状において Cookie を利用することがあります
            </p>
            <h4>アクセス解析ツールについて</h4>
            <p>
              招待状において Google アナリティクス を利用しています<br/>Googleアナリティクスはトラフィックデータの収集のためにCookieを使用しています トラフィックデータは匿名で収集されており 個人を特定するものではありません<br/>Cookieを無効にすれば これらの情報の収集を拒否することができます<br/>詳しくはお使いのブラウザの設定をご確認ください<br/>Googleアナリティクスについて詳しくは以下からご確認ください<br/>https://marketingplatform.google.com/about/analytics/terms/jp/
            </p>
            <h4>プライバシーポリシーの見直し</h4>
            <p>
              新郎 新婦は 個人情報保護に関する法令 その他の規範を遵守するとともに 本ポリシーの内容を適宜見直し その改善に努めます
            </p>
            <h4>お問い合わせ</h4>
            <p>
              個人情報の取扱に関するお問い合わせについて 新郎 新婦に直接ご連絡いただくか LINE公式アカウントへご連絡ください
            </p>
            <p>
              山本 裕稀<br/>
              山本(旧姓:高羽) 遥
            </p>
            <p>
              2023年 9月 1日 制定<br/>
            </p>
          </div>
          <a className="close-modal" onClick={closeModal}>×</a>
        </div>
      </Modal>
    </div>
  );
};

export default Privacy;