import React from 'react';
import { FadeAnimation } from './FadeAnimation';

const Event = () => {
  return(
    <div className="event">
      <FadeAnimation>
        <h2 className="eng">Event</h2>
      </FadeAnimation>
      <FadeAnimation>
        <div className="bg-white card">
          <h3>2023年11月3日(金•祝)</h3>
          <h3>挙式</h3>
            <p>14時00分</p>
            <p className="small">（受付 13時10分）</p>
            <p className="small">受付は13時30分までにおすませください</p>        
          <h3>披露宴</h3>
            <p>15時00分</p>
          <h3>会場</h3>
            <p>ルグラン軽井沢ホテル＆リゾート</p>
            <a href="https://www.legrand-karuizawaresort.jp/access/" target="_blank">https://www.legrand-karuizawaresort.jp</a>
            <p className="small">長野県北佐久郡軽井沢町発地864-4</p>
            <p className="small">0267-48-1199</p>
            <p className="caution">式場は<strong>ルグラン旧軽井沢とは異なります</strong>ので<br/>ご注意ください</p>
        </div>
      </FadeAnimation>
    </div>
  );
};

export default Event;