import { useState } from 'react';
import { createPortal } from 'react-dom';

const useModal = () => {
  const [show, setShow] = useState(false);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const Modal = ({ children }) => {
    if (!show) return null;
    return createPortal(
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '200vh',
            backgroundColor: '#9A8B8B',
            opacity: '0.5',
          }}
        ></div>
        <div style={{ position: 'relative' }}>{children}</div>
      </div>,
      document.getElementById('root')
    );
  };

  return { Modal, openModal, closeModal };
};

export default useModal;