import React, { useState, useEffect, useRef } from "react";

const LineOfficial = () => {
  const [hasScript, setHasScript] = useState(false);
  const [isButtonLoaded, setIsButtonLoaded] = useState(false);

  const wrapper = useRef(null);


  useEffect(() => {
    const script = document.createElement("script");
      script.src =
        "https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js";
      script.async = true;
      script.defer = true;
      (wrapper.current).appendChild(script);
      setHasScript(true);
  }, []);

  useEffect(() => {
    const win = window;
    if (win.LineIt && hasScript && !isButtonLoaded) {
    win.LineIt.loadButton();
    setIsButtonLoaded(true);
    }
  }, [hasScript, isButtonLoaded]);

  return(
    <div className="line-official" ref={wrapper}>
      <p>これから挙式までのご案内や<br/>挙式当日の企画をご用意しておりますので<br/>以下よりLINE公式アカウントの<br/>友達追加をお願いいたします</p>
      <div className="line-it-button" data-lang="ja" data-type="friend" data-env="REAL" data-count="true"  data-lineId="@340lbaev"></div>
    </div>
  );
};

export default LineOfficial;