import React, { useEffect, useState } from 'react';
import Buttom from './Button';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactGA from "react-ga4";


const Confirm = () => {
  const state = useLocation().state;
  function handleSend() {
    const url = 'https://hooks.slack.com/triggers/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';
    axios.post(
      url,
      JSON.stringify(
        {
          name: state.formData.name,
          furigana: state.formData.furigana,
          attendance: state.formData.attendance,
          address: state.formData.address,
          email: state.formData.email,
          tel: state.formData.tel,
          allergy: state.formData.allergy,
          prefecture: state.formData.prefecture,
          howtocome: state.formData.howtocome,
          content: state.formData.content,
          parent: state.formData.name
        }
      )
    );

    const parent = state.formData.name;
    const parent_attendance = state.formData.attendance;

    if (state.childData) {
      state.childData.map((child) => {
        axios.post(
          url,
          JSON.stringify(
            {
              attendance: parent_attendance,
              name: child.name,
              furigana: child.furigana,
              allergy: child.allergy,
              prefecture: child.prefecture,
              parent: parent
            }
          )
        );
      });
    }

    ReactGA.event("sendRsvp");
  }

  if (!state || !state.formData) {
    return (
      <div className="notfound">
        <p>データの入力状態に誤りが生じてしまいました<br/>お手数おかけしますが<br/>以下よりもう一度入力をお願いいたします</p>
        <Buttom text="出席情報を登録する" link="/rsvp" />
      </div>
      );
  }

  return(
    <div className="confirm">
      <p>以下の内容で出席情報を登録します</p>
      <div className="rsvp-form">
        <label>出欠席</label>
        <p>{state.formData.attendance}</p>
        <label>お名前</label>
        <p>{state.formData.name}</p>
        <label>ふりがな</label>
        <p>{state.formData.furigana}</p>
        {state.formData.attendance !== '欠席' && (
        <>
          <label>住所</label>
          <p>{state.formData.address}</p>
          <label>メールアドレス</label>
          <p>{state.formData.email}</p>
          <label>電話番号</label>
          <p>{state.formData.tel}</p>
          <label>アレルギー</label>
          <p>{state.formData.allergy}</p>
          <label>ご出身の都道府県</label>
          <p>{state.formData.prefecture}</p>
          <label>交通手段</label>
          <p>{state.formData.howtocome}</p>
        </>
        )}
        <label>その他</label>
        <p>{state.formData.content}</p>
        {
          state.childData && state.childData.map((child, index) => {
            return(
              <div className="companion">
                <label>{index + 1}人目</label>
                <label>お連れさまのお名前</label>
                <p>{child.name}</p>
                <label>お連れさまのふりがな</label>
                <p>{child.furigana}</p>
                <label>お連れさまのアレルギー</label>
                <p>{child.allergy}</p>
                <label>お連れさまのご出身の都道府県</label>
                <p>{child.prefecture}</p>
              </div>
            )
          })
        }
      </div>
      <p>よろしければ「登録する」を押してください</p>
      <div className="">
        <Link to='/complete' onClick={handleSend}>
          登録する
        </Link>
      </div>
      <div className="">
        <Link to={{ pathname: '/rsvp', state: { formData: state.formData, childData: state.childData}}}>
          修正する
        </Link>
      </div>
    </div>
  );
};

export default Confirm;