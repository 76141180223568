import React from 'react';
import { Link } from 'react-router-dom';

const Buttom = ({text, link}) => {
  return(
    <div className="buttom-round">
      <Link to={link}>{text}</Link>
    </div>
  );
};

export default Buttom;