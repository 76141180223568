import React from 'react';

const NotFound = () => {
  return(
    <div className="notfound">
      <p>申し訳ございません</p>
      <p>お探しのページは見つかりませんでした</p>
    </div>
  );
};

export default NotFound;