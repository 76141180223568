import React from 'react';
import Buttom from './Button';

const Announce = () => {
  return(
    <div className="announce">
        <p>郵送でのご案内状に代わり<br/>当招待状をお送りしております</p>
        <p>お手数ではございますが<br/><strong>9月30日</strong>までに以下より出席情報を<br/>ご回答いただきますようお願い申しあげます</p>
        <Buttom text="出席情報を登録する" link="/rsvp" />
    </div>
  );
};

export default Announce;